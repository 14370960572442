import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_facebook_capi_setting = _resolveComponent("facebook-capi-setting")!

  return (_openBlock(), _createBlock(_component_facebook_capi_setting, {
    "pixel-id-setting-key": _ctx.$props.pixelIdSettingKey,
    "access-token-setting-key": _ctx.$props.accessTokenSettingKey,
    title: _ctx.$props.title,
    description: _ctx.$props.description,
    pixelId: _ctx.$props.pixelId,
    pixelIdLabel: _ctx.$props.pixelIdLabel,
    pixelIdPlaceholder: _ctx.$props.pixelIdPlaceholder,
    accessToken: _ctx.$props.accessToken,
    accessTokenLabel: _ctx.$props.accessTokenLabel,
    accessTokenPlaceholder: _ctx.$props.accessTokenPlaceholder,
    loading: _ctx.isSaving,
    disabled: _ctx.isSaving,
    "btn-disabled": !_ctx.meta.dirty,
    onUpdateSetting: _ctx.updateSetting
  }, null, 8, ["pixel-id-setting-key", "access-token-setting-key", "title", "description", "pixelId", "pixelIdLabel", "pixelIdPlaceholder", "accessToken", "accessTokenLabel", "accessTokenPlaceholder", "loading", "disabled", "btn-disabled", "onUpdateSetting"]))
}