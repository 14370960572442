import { useRootStore } from '@/react/providers/RootStoreProvider'
import { Settings, DESTINATIONS } from '@/types/settings'
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ConnectionDetailsHeader from './ConnectionDetailsHeader'

const ConnectionDetailsHeaderContainer = observer(() => {
	const { settingsPipelineStore, userInfoStore } = useRootStore()

	// fetch data.
	useEffect(() => {
		settingsPipelineStore.fetch({})
		userInfoStore.fetch({})
	}, [])

	return (
		<ConnectionDetailsHeader
			pending={userInfoStore.pending || settingsPipelineStore.pending}
			storeName={userInfoStore.data?.store.name ?? ''}
			webPropertyId={
				settingsPipelineStore.data?.options[DESTINATIONS.GOOGLE]?.[
					Settings.WEB_PROPERTY_ID
				] ?? ''
			}
			measurementId={
				settingsPipelineStore.data?.options[DESTINATIONS.GOOGLE_ANALYTICS_4]?.[
					Settings.MEASUREMENT_ID
				] ?? ''
			}
			domainName={userInfoStore.data?.store.domain ?? ''}
		/>
	)
})

export default ConnectionDetailsHeaderContainer
