import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c58b9d9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "box holding-screen" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ld_button = _resolveComponent("ld-button")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.$t('settings.holding.title')), 1),
    _createElementVNode("p", null, _toDisplayString(_ctx.$t('settings.holding.paragraph_1')), 1),
    _createElementVNode("img", {
      src: require('@/assets/settings-page@2x.png'),
      alt: "settings page screenshot"
    }, null, 8, _hoisted_2),
    _createElementVNode("p", null, [
      _createTextVNode(_toDisplayString(_ctx.$t('settings.holding.paragraph_2')) + " ", 1),
      _createElementVNode("a", {
        href: `mailto:${_ctx.SUPPORT_EMAIL}?subject=${_ctx.$t(
					'connection.steps.success.supportEmailSubject'
				)}`
      }, _toDisplayString(_ctx.SUPPORT_EMAIL), 9, _hoisted_3)
    ]),
    _createVNode(_component_router_link, {
      to: { name: _ctx.CONNECTIONS }
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ld_button, {
          rounded: "",
          blue: "",
          medium: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('settings.holding.cta')), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}