
import { defineComponent, PropType } from 'vue'
import LdInput from '@/components/LdInput/LdInput.vue'
import LdButton from '@/components/LdButton/LdButton.vue'
import SettingRow from './SettingRow.vue'
import { useFieldArray } from 'vee-validate'

export default defineComponent({
	components: {
		LdInput,
		LdButton,
		SettingRow,
	},
	props: {
		settingKey: {
			type: String as PropType<string>,
			default: '',
		},
		title: {
			type: String as PropType<string>,
			default: '',
		},
		description: {
			type: String as PropType<string>,
			default: '',
		},
		placeholder: {
			type: String as PropType<string>,
			default: '',
		},
		loading: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		disabled: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		btnDisabled: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		buttonCta: {
			type: String as PropType<string>,
			default: '',
		},
	},
	emits: ['update-setting'],
	setup(props, { emit }) {
		const { remove, push, fields } = useFieldArray('linkerDomains')

		return {
			fields,
			push,
			remove,
			saveSetting: () => {
				emit('update-setting')
			},
		}
	},
})
