
import { defineComponent } from 'vue'
import { RouteNames } from '@/types/routes'
import LdButton from '@/components/LdButton/LdButton.vue'
import { SUPPORT_EMAIL } from '@/lib/constants'

export default defineComponent({
	name: 'SettingsHoldingScreen',
	components: {
		LdButton,
	},
	setup() {
		return {
			CONNECTIONS: RouteNames.CONNECTION,
			SUPPORT_EMAIL,
		}
	},
})
