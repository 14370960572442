
import { defineComponent, PropType, ref, watch } from 'vue'
import MultipleInputSetting from '@/components/Settings/MultipleInputSetting.vue'
import { useForm } from 'vee-validate'
import { AnyObjectSchema } from 'yup'
import { useStore } from 'vuex'
import { showSuccessNotification } from '@/services/notifications'
import { useI18n } from 'vue-i18n'
import { sendBehaviorEvent, AppVirtualUrls } from '@/services/segmentTracking'

export default defineComponent({
	components: {
		MultipleInputSetting,
	},
	props: {
		settingKey: {
			type: String as PropType<string>,
			default: '',
		},
		settingDestination: {
			type: String as PropType<string>,
			default: '',
		},
		title: {
			type: String as PropType<string>,
			default: '',
		},
		description: {
			type: String as PropType<string>,
			default: '',
		},
		placeholder: {
			type: String as PropType<string>,
			default: '',
		},
		value: {
			type: Array as PropType<string[]>,
			default: () => [],
		},
		validationSchema: {
			type: Object as PropType<AnyObjectSchema>,
			default: () => {},
		},
		buttonCta: {
			type: String as PropType<string>,
			default: '',
		},
	},
	setup(props) {
		const store = useStore()
		const { t } = useI18n()
		const { meta, validate, values, resetForm } = useForm({
			initialValues: {
				[props.settingKey]: props.value.length ? props.value : [''],
			},
			validationSchema: props.validationSchema,
			validateOnMount: false,
		})
		const isSaving = ref<boolean>(false)
		const payload = ref({})

		watch(
			() => props.value,
			() => {
				if (isSaving.value) {
					showSuccessNotification(
						t('settings.successNotificationTitle', { settingName: props.title })
					)
					sendBehaviorEvent({
						fieldTitle: props.title,
						actionId: props.settingKey,
						actionValue: 'Save',
						actionType: 'button',
						locationTitle: 'Data Pipeline Settings',
						virtualUrl: AppVirtualUrls.pipelineSettings,
					})

					resetForm({ values: payload.value })

					isSaving.value = false
				}
			}
		)

		return {
			updateSetting: async () => {
				const { valid } = await validate()

				if (!valid) return

				isSaving.value = true
				payload.value = { [props.settingKey]: values[props.settingKey].filter(Boolean) }

				store.dispatch('settings/updateSettings', {
					[props.settingDestination]: payload.value,
				})
			},
			isSaving,
			values,
			meta,
		}
	},
})
