
import { defineComponent, PropType, ref, watch } from 'vue'
import FacebookCapiSetting from '@/components/Settings/FacebookCapiSetting.vue'
import { AnyObjectSchema } from 'yup'
import { useForm } from 'vee-validate'
import { useStore } from 'vuex'
import { showSuccessNotification } from '@/services/notifications'
import { useI18n } from 'vue-i18n'
import { sendBehaviorEvent, AppVirtualUrls } from '@/services/segmentTracking'

export default defineComponent({
	components: {
		FacebookCapiSetting,
	},
	props: {
		settingDestination: {
			type: String as PropType<string>,
			default: '',
		},
		title: {
			type: String as PropType<string>,
			default: '',
		},
		description: {
			type: String as PropType<string>,
			default: '',
		},
		placeholder: {
			type: String as PropType<string>,
			default: '',
		},
		validationSchema: {
			type: Object as PropType<AnyObjectSchema>,
			default: () => {},
		},
		pixelId: {
			type: String as PropType<string>,
			default: '',
		},
		accessToken: {
			type: String as PropType<string>,
			default: '',
		},
		pixelIdLabel: {
			type: String as PropType<string>,
			default: '',
		},
		accessTokenLabel: {
			type: String as PropType<string>,
			default: '',
		},
		pixelIdSettingKey: {
			type: String as PropType<string>,
			default: '',
		},
		accessTokenSettingKey: {
			type: String as PropType<string>,
			default: '',
		},
		pixelIdPlaceholder: {
			type: String as PropType<string>,
			default: '',
		},
		accessTokenPlaceholder: {
			type: String as PropType<string>,
			default: '',
		},
	},
	setup(props) {
		const store = useStore()
		const { t } = useI18n()
		const ctaDisabled = ref<boolean>(true)
		const isSaving = ref<boolean>(false)
		const { meta, validate, values, resetForm } = useForm({
			validationSchema: props.validationSchema,
			validateOnMount: false,
		})
		const payload = ref({})

		watch(
			() => [props.pixelId, props.accessToken],
			() => {
				if (isSaving.value) {
					showSuccessNotification(
						t('settings.successNotificationTitle', { settingName: props.title })
					)

					sendBehaviorEvent({
						fieldTitle: props.title,
						actionId: props.pixelId,
						actionValue: 'Save',
						actionType: 'button',
						locationTitle: 'Data Pipeline Settings',
						virtualUrl: AppVirtualUrls.pipelineSettings,
					})

					resetForm({ values: payload.value })

					isSaving.value = false
				}
			}
		)

		return {
			meta,
			ctaDisabled,
			updateSetting: async () => {
				const { valid } = await validate()

				if (!valid) return

				payload.value = {
					[props.pixelIdSettingKey]: values[props.pixelIdSettingKey],
					[props.accessTokenSettingKey]: values[props.accessTokenSettingKey],
				}

				isSaving.value = true

				store.dispatch('settings/updateSettings', {
					[props.settingDestination]: payload.value,
				})
			},
			isSaving,
		}
	},
})
