
import { defineComponent, PropType } from 'vue'
import SettingRow from './SettingRow.vue'

export default defineComponent({
	name: 'SettingsItem',
	components: {
		SettingRow,
	},
	props: {
		title: {
			type: String as PropType<string>,
			default: '',
		},
		description: {
			type: String as PropType<string>,
			default: '',
		},
		disabled: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		settingKey: {
			type: String as PropType<string>,
			default: '',
		},
		modelValue: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		loading: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		btnDisabled: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
	},
	emits: ['update-setting', 'update:modelValue'],
	setup(props, { emit }) {
		return {
			saveSetting: () => {
				emit('update-setting')
			},
			updateValue: (value: boolean) => {
				emit('update:modelValue', value)
			},
		}
	},
})
