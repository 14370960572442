
import { defineComponent, PropType, ref, watch } from 'vue'
import SwitchSetting from '@/components/Settings/SwitchSetting.vue'
import { useStore } from 'vuex'
import { AnyObjectSchema } from 'yup'
import { showSuccessNotification } from '@/services/notifications'
import { useI18n } from 'vue-i18n'
import { sendBehaviorEvent, AppVirtualUrls } from '@/services/segmentTracking'

export default defineComponent({
	components: {
		SwitchSetting,
	},
	props: {
		settingKey: {
			type: String as PropType<string>,
			default: '',
		},
		settingDestination: {
			type: String as PropType<string>,
			default: '',
		},
		title: {
			type: String as PropType<string>,
			default: '',
		},
		description: {
			type: String as PropType<string>,
			default: '',
		},
		placeholder: {
			type: String as PropType<string>,
			default: '',
		},
		value: {
			type: Boolean as PropType<boolean>,
			default: null,
		},
		validationSchema: {
			type: Object as PropType<AnyObjectSchema>,
			default: () => {},
		},
	},
	setup(props) {
		const store = useStore()
		const { t } = useI18n()
		const currentValue = ref<boolean>(props.value)
		const ctaDisabled = ref<boolean>(true)
		const isSaving = ref<boolean>(false)

		watch([currentValue, () => props.value], ([newCurrentValue, propValue]) => {
			if (isSaving.value) {
				showSuccessNotification(
					t('settings.successNotificationTitle', { settingName: props.title })
				)

				sendBehaviorEvent({
					fieldTitle: props.title,
					actionId: props.settingKey,
					actionValue: `Save ${currentValue.value ? 'ON' : 'OFF'}`,
					actionType: 'button',
					locationTitle: 'Data Pipeline Settings',
					virtualUrl: AppVirtualUrls.pipelineSettings,
				})

				isSaving.value = false
			}
			ctaDisabled.value = newCurrentValue === propValue
		})

		return {
			currentValue,
			ctaDisabled,
			isSaving,
			updateSetting: async () => {
				isSaving.value = true
				store.dispatch('settings/updateSettings', {
					[props.settingDestination]: {
						[props.settingKey]: currentValue.value,
					},
				})
			},
		}
	},
})
