
import { defineComponent, PropType } from 'vue'
import LdButton from '@/components/LdButton/LdButton.vue'

export default defineComponent({
	name: 'SettingRow',
	components: {
		LdButton,
	},
	props: {
		title: {
			type: String as PropType<string>,
			default: '',
		},
		description: {
			type: String as PropType<string>,
			default: '',
		},
		loading: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		disabled: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
	},
	emits: ['save'],
	setup() {},
})
