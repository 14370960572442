import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5eaef464"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "my-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ld_input = _resolveComponent("ld-input")!
  const _component_ld_button = _resolveComponent("ld-button")!
  const _component_setting_row = _resolveComponent("setting-row")!

  return (_openBlock(), _createBlock(_component_setting_row, {
    title: _ctx.$props.title,
    description: _ctx.$props.description,
    loading: _ctx.$props.loading,
    disabled: _ctx.$props.btnDisabled,
    onSave: _ctx.saveSetting
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (field, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: field.key,
            class: "input-group"
          }, [
            (_openBlock(), _createBlock(_component_ld_input, {
              key: field.key,
              type: "text",
              name: `${_ctx.$props.settingKey}[${index}]`,
              placeholder: _ctx.$props.placeholder,
              medium: "",
              "sr-only": "",
              disabled: _ctx.disabled
            }, null, 8, ["name", "placeholder", "disabled"]))
          ]))
        }), 128)),
        _createVNode(_component_ld_button, {
          class: "add-input",
          medium: "",
          blue: "",
          rounded: "",
          disabled: _ctx.disabled,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.push('')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$props.buttonCta), 1)
          ]),
          _: 1
        }, 8, ["disabled"])
      ])
    ]),
    _: 1
  }, 8, ["title", "description", "loading", "disabled", "onSave"]))
}