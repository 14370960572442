import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ld_switch = _resolveComponent("ld-switch")!
  const _component_setting_row = _resolveComponent("setting-row")!

  return (_openBlock(), _createBlock(_component_setting_row, {
    title: _ctx.title,
    description: _ctx.description,
    loading: _ctx.loading,
    disabled: _ctx.btnDisabled,
    onSave: _ctx.saveSetting
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ld_switch, {
        isChecked: _ctx.modelValue,
        isDisabled: _ctx.disabled,
        ".onClick": _ctx.updateValue
      }, null, 8, ["isChecked", "isDisabled", ".onClick"])
    ]),
    _: 1
  }, 8, ["title", "description", "loading", "disabled", "onSave"]))
}