import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const HeaderDescription = styled.div`
	text-transform: uppercase;
	font-weight: 700;
	font-size: 14px;
	color: $grey;
	line-height: 2;
`
const ConnectionDetailsHeader = ({
	webPropertyId,
	measurementId,
	domainName,
	storeName,
	pending,
}: {
	domainName: string
	webPropertyId?: string
	measurementId?: string
	storeName: string
	pending: boolean
}) => {
	const { t } = useTranslation()

	if (pending) {
		return <ld-loader></ld-loader>
	}

	return (
		<ld-paper>
			<div slot="content">
				<div className="columns is-tablet is-align-items-center">
					<div className="column is-narrow is-hidden-mobile">
						<ld-icon icon="hummingbird"></ld-icon>
					</div>

					<div className="column">
						<h1 className="mt-0 mb-2 is-size-3">{storeName}</h1>

						<HeaderDescription>
							{t('settings.header.storeUrl')}
							<ld-tag isMedium="true">{domainName}</ld-tag>
							<br className="is-hidden-tablet" />
							{webPropertyId && (
								<span>
									{t('settings.header.webPropertyId')}
									<ld-tag isMedium="true">{webPropertyId}</ld-tag>
								</span>
							)}
							{measurementId && (
								<span>
									{t('settings.header.measurementId')}
									<ld-tag isMedium="true">{measurementId}</ld-tag>
								</span>
							)}
						</HeaderDescription>
					</div>
				</div>
			</div>
		</ld-paper>
	)
}

export default ConnectionDetailsHeader
