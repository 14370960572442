import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_measurement_id_setting = _resolveComponent("measurement-id-setting")!

  return (_openBlock(), _createBlock(_component_measurement_id_setting, {
    "measurement-id-setting-key": _ctx.$props.measurementIdSettingKey,
    "api-secret-setting-key": _ctx.$props.apiSecretSettingKey,
    title: _ctx.$props.title,
    description: _ctx.$props.description,
    measurementId: _ctx.$props.measurementId,
    measurementIdLabel: _ctx.$props.measurementIdLabel,
    measurementIdPlaceholder: _ctx.$props.measurementIdPlaceholder,
    apiSecret: _ctx.$props.apiSecret,
    apiSecretLabel: _ctx.$props.apiSecretLabel,
    apiSecretPlaceholder: _ctx.$props.apiSecretPlaceholder,
    loading: _ctx.isSaving,
    disabled: _ctx.isSaving,
    "btn-disabled": !_ctx.meta.dirty,
    onUpdateSetting: _ctx.updateSetting
  }, null, 8, ["measurement-id-setting-key", "api-secret-setting-key", "title", "description", "measurementId", "measurementIdLabel", "measurementIdPlaceholder", "apiSecret", "apiSecretLabel", "apiSecretPlaceholder", "loading", "disabled", "btn-disabled", "onUpdateSetting"]))
}