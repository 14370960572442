import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_multiple_input_setting = _resolveComponent("multiple-input-setting")!

  return (_openBlock(), _createBlock(_component_multiple_input_setting, {
    "setting-key": _ctx.$props.settingKey,
    title: _ctx.$props.title,
    description: _ctx.$props.description,
    placeholder: _ctx.$props.placeholder,
    loading: _ctx.isSaving,
    disabled: _ctx.isSaving,
    "btn-disabled": !_ctx.meta.dirty,
    buttonCta: _ctx.$props.buttonCta,
    onUpdateSetting: _ctx.updateSetting
  }, null, 8, ["setting-key", "title", "description", "placeholder", "loading", "disabled", "btn-disabled", "buttonCta", "onUpdateSetting"]))
}