import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ld_input = _resolveComponent("ld-input")!
  const _component_setting_row = _resolveComponent("setting-row")!

  return (_openBlock(), _createBlock(_component_setting_row, {
    title: _ctx.$props.title,
    description: _ctx.$props.description,
    loading: _ctx.$props.loading,
    disabled: _ctx.btnDisabled,
    onSave: _ctx.saveSetting
  }, {
    default: _withCtx(() => [
      _createElementVNode("label", null, _toDisplayString(_ctx.$props.pixelIdLabel), 1),
      _createVNode(_component_ld_input, {
        name: _ctx.$props.pixelIdSettingKey,
        type: "text",
        placeholder: _ctx.$props.pixelIdPlaceholder,
        medium: "",
        "sr-only": "",
        class: "my-3",
        value: _ctx.$props.pixelId,
        disabled: _ctx.disabled
      }, null, 8, ["name", "placeholder", "value", "disabled"]),
      _createElementVNode("label", null, _toDisplayString(_ctx.$props.accessTokenLabel), 1),
      _createVNode(_component_ld_input, {
        name: _ctx.$props.accessTokenSettingKey,
        type: "text",
        placeholder: _ctx.$props.accessTokenPlaceholder,
        medium: "",
        "sr-only": "",
        class: "my-3",
        value: _ctx.$props.accessToken,
        disabled: _ctx.disabled
      }, null, 8, ["name", "placeholder", "value", "disabled"])
    ]),
    _: 1
  }, 8, ["title", "description", "loading", "disabled", "onSave"]))
}