import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_switch_setting = _resolveComponent("switch-setting")!

  return (_openBlock(), _createBlock(_component_switch_setting, {
    modelValue: _ctx.currentValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentValue) = $event)),
    "setting-key": _ctx.$props.settingKey,
    title: _ctx.$props.title,
    description: _ctx.$props.description,
    placeholder: _ctx.$props.placeholder,
    loading: _ctx.isSaving,
    disabled: _ctx.isSaving,
    "btn-disabled": _ctx.ctaDisabled,
    onUpdateSetting: _ctx.updateSetting
  }, null, 8, ["modelValue", "setting-key", "title", "description", "placeholder", "loading", "disabled", "btn-disabled", "onUpdateSetting"]))
}