export enum DESTINATIONS {
	GOOGLE = 'google',
	GOOGLE_ANALYTICS_4 = 'googleAnalytics4',
	FACEBOOK = 'facebook',
}

export enum Settings {
	WEB_PROPERTY_ID = 'webPropertyId',
	GOOGLE_SIGNALS = 'googleSignals',
	ANONYMIZE_IP = 'anonymizeIp',
	LINKER_DOMAINS = 'linkerDomains',
	MEASUREMENT_ID = 'measurementId',
	API_SECRET = 'apiSecret',
	PIXEL_ID = 'pixelId',
	ACCESS_TOKEN = 'accessToken',
}

export interface GoogleAnalytics3Settings {
	[Settings.WEB_PROPERTY_ID]?: string
	[Settings.GOOGLE_SIGNALS]?: boolean
	[Settings.ANONYMIZE_IP]?: boolean
	[Settings.LINKER_DOMAINS]?: string[]
}

export interface GoogleAnalytics4Settings {
	[Settings.MEASUREMENT_ID]: string
	[Settings.API_SECRET]: string
}

export interface FacebookSettings {
	[Settings.PIXEL_ID]: string
	[Settings.ACCESS_TOKEN]: string
}

export interface PipelineSettings {
	[DESTINATIONS.GOOGLE]?: GoogleAnalytics3Settings
	[DESTINATIONS.GOOGLE_ANALYTICS_4]?: GoogleAnalytics4Settings
	[DESTINATIONS.FACEBOOK]?: FacebookSettings
}

export interface PipelineSettingsResponse {
	storeId: string
	options: PipelineSettings
}
