
import { defineComponent, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import SettingsListContainer from './SettingsListContainer.vue'
import HoldingScreen from './HoldingScreen.vue'
import { sendPageViewEvent, AppVirtualUrls } from '@/services/segmentTracking'

import ConnectionDetailsHeaderContainer from '@/react/components/ConnectionDetailsHeader/ConnectionDetailsHeaderContainer'
import { renderReactComponent } from '@/utils/react'

export default defineComponent({
	name: 'Pipeline',
	components: {
		HoldingScreen,
		SettingsListContainer,
	},
	setup() {
		const store = useStore()
		const storeData = computed(() => store.getters['user/me/getState'])
		const settingsData = computed(() => store.getters['settings/pipeline/getState'])

		onMounted(() => {
			renderReactComponent(
				ConnectionDetailsHeaderContainer,
				document.getElementById('connection-details-header')
			)

			sendPageViewEvent({
				locationTitle: 'Data Pipeline Settings',
				virtualUrl: AppVirtualUrls.pipelineSettings,
			})
		})

		return {
			store: storeData,
			settings: settingsData,
		}
	},
})
