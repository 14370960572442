
import { defineComponent, PropType } from 'vue'
import InputSettingContainer from './InputSettingContainer.vue'
import SwitchSettingContainer from './SwitchSettingContainer.vue'
import MultipleInputSettingContainer from './MultipleInputSettingContainer.vue'
import MeasurementIdSettingContainer from './MeasurementIdSettingContainer.vue'
import FacebookCapiSettingContainer from './FacebookCapiSettingContainer.vue'
import Schemas from '@/lib/validationSchemas/settings'
import { Settings, DESTINATIONS, PipelineSettings } from '@/types/settings'

export default defineComponent({
	name: 'SettingsListContainer',
	components: {
		InputSettingContainer,
		SwitchSettingContainer,
		MultipleInputSettingContainer,
		MeasurementIdSettingContainer,
		FacebookCapiSettingContainer,
	},
	props: {
		settings: {
			type: Object as PropType<PipelineSettings>,
			default: () => {},
		},
	},
	setup() {
		return {
			Settings,
			Schemas,
			DESTINATIONS,
		}
	},
})
