import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { slot: "content" }
const _hoisted_2 = { class: "mt-0 mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_measurement_id_setting_container = _resolveComponent("measurement-id-setting-container")!
  const _component_facebook_capi_setting_container = _resolveComponent("facebook-capi-setting-container")!
  const _component_input_setting_container = _resolveComponent("input-setting-container")!
  const _component_switch_setting_container = _resolveComponent("switch-setting-container")!
  const _component_multiple_input_setting_container = _resolveComponent("multiple-input-setting-container")!
  const _component_ld_paper = _resolveComponent("ld-paper")!

  return (_openBlock(), _createBlock(_component_ld_paper, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('settings.sectionTitle')), 1),
        _createVNode(_component_measurement_id_setting_container, {
          "measurement-id-setting-key": _ctx.Settings.MEASUREMENT_ID,
          "api-secret-setting-key": _ctx.Settings.API_SECRET,
          settingDestination: _ctx.DESTINATIONS.GOOGLE_ANALYTICS_4,
          title: _ctx.$t('settings.ga4.title'),
          description: _ctx.$t('settings.ga4.description'),
          measurementId: 
					_ctx.settings.data.options[_ctx.DESTINATIONS.GOOGLE_ANALYTICS_4]?.measurementId
				,
          measurementIdLabel: _ctx.$t('settings.ga4.measurementIdLabel'),
          measurementIdPlaceholder: _ctx.$t('settings.ga4.measurementIdPlaceholder'),
          apiSecret: _ctx.settings.data.options[_ctx.DESTINATIONS.GOOGLE_ANALYTICS_4]?.apiSecret,
          apiSecretLabel: _ctx.$t('settings.ga4.apiSecretLabel'),
          apiSecretPlaceholder: _ctx.$t('settings.ga4.apiSecretPlaceholder'),
          "validation-schema": _ctx.Schemas[_ctx.Settings.MEASUREMENT_ID]
        }, null, 8, ["measurement-id-setting-key", "api-secret-setting-key", "settingDestination", "title", "description", "measurementId", "measurementIdLabel", "measurementIdPlaceholder", "apiSecret", "apiSecretLabel", "apiSecretPlaceholder", "validation-schema"]),
        _createVNode(_component_facebook_capi_setting_container, {
          "pixel-id-setting-key": _ctx.Settings.PIXEL_ID,
          "access-token-setting-key": _ctx.Settings.ACCESS_TOKEN,
          settingDestination: _ctx.DESTINATIONS.FACEBOOK,
          title: _ctx.$t('settings.facebookCapi.title'),
          description: _ctx.$t('settings.facebookCapi.description'),
          pixelId: _ctx.settings.data.options[_ctx.DESTINATIONS.FACEBOOK]?.pixelId,
          pixelIdLabel: _ctx.$t('settings.facebookCapi.pixelIdLabel'),
          pixelIdPlaceholder: _ctx.$t('settings.facebookCapi.pixelIdPlaceholder'),
          accessToken: _ctx.settings.data.options[_ctx.DESTINATIONS.FACEBOOK]?.accessToken,
          accessTokenLabel: _ctx.$t('settings.facebookCapi.accessTokenLabel'),
          accessTokenPlaceholder: _ctx.$t('settings.facebookCapi.accessTokenPlaceholder'),
          "validation-schema": _ctx.Schemas[_ctx.Settings.PIXEL_ID]
        }, null, 8, ["pixel-id-setting-key", "access-token-setting-key", "settingDestination", "title", "description", "pixelId", "pixelIdLabel", "pixelIdPlaceholder", "accessToken", "accessTokenLabel", "accessTokenPlaceholder", "validation-schema"]),
        _createVNode(_component_input_setting_container, {
          "setting-key": _ctx.Settings.WEB_PROPERTY_ID,
          settingDestination: _ctx.DESTINATIONS.GOOGLE,
          title: _ctx.$t('settings.webPropertyId.title'),
          description: _ctx.$t('settings.webPropertyId.description'),
          placeholder: _ctx.$t('settings.webPropertyId.placeholder'),
          value: _ctx.settings.data.options[_ctx.DESTINATIONS.GOOGLE]?.webPropertyId,
          "validation-schema": _ctx.Schemas[_ctx.Settings.WEB_PROPERTY_ID]
        }, null, 8, ["setting-key", "settingDestination", "title", "description", "placeholder", "value", "validation-schema"]),
        _createVNode(_component_switch_setting_container, {
          "setting-key": _ctx.Settings.ANONYMIZE_IP,
          settingDestination: _ctx.DESTINATIONS.GOOGLE,
          title: _ctx.$t('settings.anonymizeIp.title'),
          description: _ctx.$t('settings.anonymizeIp.description'),
          value: _ctx.settings.data.options[_ctx.DESTINATIONS.GOOGLE]?.anonymizeIp,
          "validation-schema": _ctx.Schemas[_ctx.Settings.ANONYMIZE_IP]
        }, null, 8, ["setting-key", "settingDestination", "title", "description", "value", "validation-schema"]),
        _createVNode(_component_switch_setting_container, {
          "setting-key": _ctx.Settings.GOOGLE_SIGNALS,
          settingDestination: _ctx.DESTINATIONS.GOOGLE,
          title: _ctx.$t('settings.googleSignals.title'),
          description: _ctx.$t('settings.googleSignals.description'),
          value: _ctx.settings.data.options[_ctx.DESTINATIONS.GOOGLE]?.googleSignals,
          "validation-schema": _ctx.Schemas[_ctx.Settings.GOOGLE_SIGNALS]
        }, null, 8, ["setting-key", "settingDestination", "title", "description", "value", "validation-schema"]),
        _createVNode(_component_multiple_input_setting_container, {
          "setting-key": _ctx.Settings.LINKER_DOMAINS,
          settingDestination: _ctx.DESTINATIONS.GOOGLE,
          title: _ctx.$t('settings.linkerDomains.title'),
          description: _ctx.$t('settings.linkerDomains.description'),
          placeholder: _ctx.$t('settings.linkerDomains.placeholder'),
          value: _ctx.settings.data.options[_ctx.DESTINATIONS.GOOGLE]?.linkerDomains,
          "validation-schema": _ctx.Schemas[_ctx.Settings.LINKER_DOMAINS],
          buttonCta: _ctx.$t('settings.linkerDomains.buttonCta')
        }, null, 8, ["setting-key", "settingDestination", "title", "description", "placeholder", "value", "validation-schema", "buttonCta"])
      ])
    ]),
    _: 1
  }))
}