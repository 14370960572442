import {
	propertyID,
	linkerDomains,
	measurementId,
	apiSecret,
	pixelId,
	fbCapiAccessToken,
} from '@/lib/validators'
import { Settings } from '@/types/settings'
import { i18n } from '@/main'
import { array, boolean, object, string } from 'yup'

// to use t outside setup/component
// https://github.com/intlify/vue-i18n-next/issues/113#issuecomment-695770030
// How to check two field if they are dependent on each other:
// https://dev.to/gabrielterriaga/how-to-validate-two-fields-that-depend-on-each-other-with-yup-1ccg
const { t } = i18n.global
const PipelineSettingsSchemas = {
	[Settings.MEASUREMENT_ID]: object({}).shape(
		{
			[Settings.MEASUREMENT_ID]: string()
				.ensure()
				.when(['apiSecret'], {
					is: (value: string) => value !== '',
					then: (schema) =>
						schema.matches(measurementId, {
							message: t(`settings.ga4.${Settings.MEASUREMENT_ID}Error`),
						}),
					otherwise: (schema) =>
						schema.matches(measurementId, {
							message: t(`settings.ga4.${Settings.MEASUREMENT_ID}Error`),
							excludeEmptyString: true,
						}),
				}),
			[Settings.API_SECRET]: string()
				.ensure()
				.when(['measurementId'], {
					is: (value: string) => value !== '',
					then: (schema) =>
						schema.matches(apiSecret, {
							message: t(`settings.ga4.${Settings.API_SECRET}Error`),
						}),
					otherwise: (schema) =>
						schema.matches(apiSecret, {
							message: t(`settings.ga4.${Settings.API_SECRET}Error`),
							excludeEmptyString: true,
						}),
				}),
		},
		[['measurementId', 'apiSecret']]
	),
	[Settings.PIXEL_ID]: object({}).shape(
		{
			[Settings.PIXEL_ID]: string()
				.ensure()
				.when(['accessToken'], {
					is: (value: string) => value !== '',
					then: (schema) =>
						schema.matches(pixelId, {
							message: t(`settings.facebookCapi.${Settings.PIXEL_ID}Error`),
						}),
					otherwise: (schema) =>
						schema.matches(pixelId, {
							message: t(`settings.facebookCapi.${Settings.PIXEL_ID}Error`),
							excludeEmptyString: true,
						}),
				}),
			[Settings.ACCESS_TOKEN]: string()
				.ensure()
				.when(['pixelId'], {
					is: (value: string) => value !== '',
					then: (schema) =>
						schema.matches(fbCapiAccessToken, {
							message: t(`settings.facebookCapi.${Settings.ACCESS_TOKEN}Error`),
						}),
					otherwise: (schema) =>
						schema.matches(fbCapiAccessToken, {
							message: t(`settings.facebookCapi.${Settings.ACCESS_TOKEN}Error`),
							excludeEmptyString: true,
						}),
				}),
		},
		[['pixelId', 'accessToken']]
	),
	[Settings.WEB_PROPERTY_ID]: object({
		[Settings.WEB_PROPERTY_ID]: string().matches(
			propertyID,
			t(`settings.${Settings.WEB_PROPERTY_ID}.error`)
		),
	}),
	[Settings.ANONYMIZE_IP]: boolean(),
	[Settings.GOOGLE_SIGNALS]: boolean(),
	[Settings.LINKER_DOMAINS]: object({
		[Settings.LINKER_DOMAINS]: array().of(
			string().matches(linkerDomains, {
				excludeEmptyString: true,
				message: t(`settings.${Settings.LINKER_DOMAINS}.error`),
			})
		),
	}),
}

export default PipelineSettingsSchemas
