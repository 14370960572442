
import { defineComponent, PropType } from 'vue'
import SettingRow from './SettingRow.vue'
import LdInput from '@/components/LdInput/LdInput.vue'

export default defineComponent({
	name: 'InputSetting',
	components: {
		SettingRow,
		LdInput,
	},
	props: {
		measurementIdSettingKey: {
			type: String as PropType<string>,
			default: '',
		},
		apiSecretSettingKey: {
			type: String as PropType<string>,
			default: '',
		},
		title: {
			type: String as PropType<string>,
			default: '',
		},
		description: {
			type: String as PropType<string>,
			default: '',
		},
		settingKey: {
			type: String as PropType<string>,
			default: '',
		},
		measurementId: {
			type: String as PropType<string>,
			default: '',
		},
		loading: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		disabled: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		btnDisabled: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		measurementIdLabel: {
			type: String as PropType<string>,
			default: '',
		},
		apiSecretLabel: {
			type: String as PropType<string>,
			default: '',
		},
		measurementIdPlaceholder: {
			type: String as PropType<string>,
			default: '',
		},
		apiSecretPlaceholder: {
			type: String as PropType<string>,
			default: '',
		},
		apiSecret: {
			type: String as PropType<string>,
			default: '',
		},
	},
	emits: ['update-setting'],
	setup(props, { emit }) {
		return {
			saveSetting: () => {
				emit('update-setting')
			},
		}
	},
})
